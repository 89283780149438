import * as z from 'zod'
import { SportShape } from './sport'

export const SeasonShape = z.union([
    z.literal('2020'),
    z.literal('2021'),
    z.literal('2022'),
    z.literal('2023'),
    z.literal('2024'),
    z.literal('2021-22'),
    z.literal('2022-23'),
])
export type SeasonName = z.TypeOf<typeof SeasonShape>

export const SeasonConfigShape = z.object({
    prizing: z.array(
        z.object({
            title: z.string(),
            content: z.string(),
            image: z.string().optional(),
            subtext: z.string().optional(),
        }),
    ),
    termsAndConditions: z.string(),
    ingestionCompId: z.string(),
    tabTouchCompId: z.string().optional(),
    sendGridId: z.string(),
    expertTippingCompId: z.string().optional(),
})

export const SeasonUpdatedLifecycleEventShape = z.object({
    type: z.literal('season-updated-lifecycle-event'),
    sport: SportShape,
    season: SeasonShape,
    published: z.boolean(),
})

export const SeasonConfiguredLifecycleEventShape = z.object({
    type: z.literal('season-configured-lifecycle-event'),
    sport: SportShape,
    season: SeasonShape,
    config: SeasonConfigShape,
})

export const ReconcileMatchRoundEventShape = z.object({
    type: z.literal('reconcile-match-round-event'),
    sport: SportShape,
    season: SeasonShape,
    reason: z.string().nonempty(),
})

export const ToggleSeasonLockEventShape = z.object({
    type: z.literal('toggle-lock'),
    sport: SportShape,
    season: SeasonShape,
    reason: z.string().nonempty(),
})

export type SeasonUpdatedLifecycleEvent = z.TypeOf<
    typeof SeasonUpdatedLifecycleEventShape
>

export type ReconcileMatchRoundEvent = z.TypeOf<
    typeof ReconcileMatchRoundEventShape
>

export type ToggleSeasonLockEvent = z.TypeOf<typeof ToggleSeasonLockEventShape>

export type SeasonConfiguredLifecycleEvent = z.TypeOf<
    typeof SeasonConfiguredLifecycleEventShape
>
